import { USStatesEnum, RenderModeEnum } from '@/enums';
import CanadianProvincesEnum from '../enums/CanadianProvincesEnum';
import * as Core from '../../nova/core';
import moment from 'moment-timezone';
import { isObject } from 'class-validator';
import SizingEnum from '@satellite/enums/SizingEnum';
import mirandaUtil from '@satellite/plugins/MirandaUtil';
import { isString } from 'lodash';

export function isMobileDevice() {
  return /Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function isMobile() {
  const currentRenderMode = localStorage.getItem('renderMode');
  return isMobileDevice() && currentRenderMode !== RenderModeEnum.DESKTOP;
}

export default {
  scrollToElement(element, offset = 45) {
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  },
  // TODO (API DEPENDENT) this function really shouldn't need to exist
  // Hopefully we can relax the API in the future so that will except days with an empty array
  filterSchedule(schedule) {
    return Object.keys(schedule)
      .filter(k => k === 'version' || schedule[k].length > 0)
      .reduce((a, k) => ({ ...a, [k]: schedule[k] }), {});
  },
  sortObjectArray(arr, sortBy, desc = false) {
    arr.sort((a, b) =>
      (desc && a[sortBy] < b[sortBy]) || (!desc && a[sortBy] > b[sortBy]) ? 1 : -1
    );
  },
  sortObjectByKeys(obj) {
    return Object.keys(obj)
      .sort()
      .reduce((o, k) => {
        o[k] = obj[k];
        return o;
      }, {});
  },
  formatStringToDate(string, inputFormat, outputFormat) {
    return momentjs(string, inputFormat).format(outputFormat);
  },
  getAmericanStateSelectOptions() {
    return this.InvertObjectKeyValuePairs(USStatesEnum);
  },
  getCanadianProvinceSelectOptions() {
    return this.InvertObjectKeyValuePairs(CanadianProvincesEnum);
  },
  InvertObjectKeyValuePairs(object) {
    const invertedObject = {};
    Object.keys(object).forEach(key => {
      invertedObject[object[key]] = key;
    });

    return invertedObject;
  },
  removeCursorTextListener($el, textElId) {
    const cursorElement = document.getElementById(textElId);
    if (cursorElement) {
      cursorElement.remove();
    }

    $el.removeEventListener('mouseover', () => {});
    $el.removeEventListener('mouseout', () => {});
    $el.removeEventListener('mousemove', () => {});
  },
  addCursorTextListener($el, text, textElId) {
    let $cursorTextEl = document.createElement('div');
    $cursorTextEl.className = 'cursorText';
    $cursorTextEl.id = textElId;
    $cursorTextEl.innerHTML = text;
    $el.appendChild($cursorTextEl);

    function moveCursor(e) {
      if (!e) {
        e = window.event;
      }
      $cursorTextEl.style.left = e.clientX + 30 + 'px';
      $cursorTextEl.style.top = e.clientY + 'px';
    }
    function showCursorText() {
      if ($el.classList.contains('disabled') && !$el.classList.contains('clickable')) {
        $cursorTextEl.style.display = 'block';
      }
    }
    function hideCursorText() {
      $cursorTextEl.style.display = 'none';
    }

    $el.onmouseover = showCursorText;
    $el.onmouseout = hideCursorText;
    $el.onmousemove = moveCursor;
  },

  registerComponentsInDirectory(requireComponent, VuePrototype) {
    requireComponent.keys().forEach(fileName => {
      // Get component config
      const componentConfig = requireComponent(fileName);

      // Get PascalCase name of component
      const componentName = Core.upperFirst(
        // Gets the file name regardless of folder depth
        fileName
          .split('/')
          .pop()
          .replace(/\.\w+$/, '')
      );

      // Register component globally
      VuePrototype.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
      );
    });

    return VuePrototype;
  },
  makeTimeDiff(start, end, timezone, unit = 'milliseconds', decimals = 0) {
    const startMoment = moment.tz(start, timezone).startOf('minute');
    const endMoment = moment.tz(end, timezone).startOf('minute');

    return endMoment.diff(startMoment, unit, true).toFixed(decimals);
  },
  // Deprecated in favor of ExtendedAppointment.js's getReadableDuration method if possible
  getReadableDuration(startTime, endTime, timezone, label = '') {
    let readableDuration = '';
    const diff = parseInt(this.makeTimeDiff(startTime, endTime, timezone));

    if (diff > 0) {
      let duration = moment.duration(diff);
      let days = duration.clone().days();
      let hours = duration.clone().hours();
      let minutes = duration.clone().minutes();
      readableDuration += days ? `${days}` : '';
      readableDuration += days ? (days > 1 ? ' days ' : ' day ') : '';
      readableDuration += hours ? `${hours}` : '';
      readableDuration += hours ? (hours > 1 ? ' hrs ' : ' hr ') : '';
      readableDuration += minutes ? `${minutes} min` : '';
      readableDuration += label ? ` ${label}` : '';
    } else {
      if (label.toLowerCase().trim() === 'dwell') {
        if (diff === 0) {
          readableDuration = '0 min dwell';
        }
      } else if (label.toLowerCase().trim() === 'late') {
        readableDuration = diff === 0 ? 'On Time' : 'Early';
      } else if (label.toLowerCase().trim() === 'wait') {
        if (diff === 0) {
          readableDuration = 'No wait time';
        }
      }
    }

    return readableDuration.trim().replace(/  +/g, ' ');
  },
  clearLocalStorage(items) {
    if (items?.length) {
      items.map(item => localStorage.removeItem(item));
    } else {
      localStorage.clear();
    }
  },
  clearSessionStorage(items) {
    if (items?.length) {
      items.map(item => sessionStorage.removeItem(item));
    } else {
      sessionStorage.clear();
    }
  },
  getCustomTagData(customTags = [], name = '') {
    customTags = customTags ?? [];
    return customTags.find(ct => ct.name === name);
  },
  makeTagObject(customTags = [], tag) {
    customTags = customTags ?? [];
    const name = typeof tag === 'string' ? tag : tag.name;
    const customTag = this.getCustomTagData(customTags, name);

    return customTag ?? { name, color: null, textColor: null };
  },
  getReadableChildDockName(parentDock) {
    return `Parallel capacity: ${parentDock.name}`;
  },
  /**
   * Deep merge two objects.
   * https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge#answer-37164538
   */
  mergeDeep(target, source) {
    const output = { ...{}, ...target };
    if (isObject(target) && isObject(source)) {
      Object.keys(source).forEach(key => {
        if (isObject(source[key])) {
          if (!(key in target)) {
            Object.assign(output, { [key]: source[key] });
          } else {
            output[key] = this.mergeDeep(target[key], source[key]);
          }
        } else {
          Object.assign(output, { [key]: source[key] });
        }
      });
    }
    return output;
  },
  // This is to push information onto the datalayer which is used by google tag manager and google analytics
  pushMeToDataLayer(me) {
    const { companyId, email, firstName, lastName, orgId, orgName, role, id } = me;

    window.dataLayer.push({
      user: {
        id,
        companyId,
        email,
        firstName,
        lastName,
        orgId,
        orgName,
        role
      }
    });
  },
  deleteConfirmationInputText(count, entity) {
    return `Yes, I am aware that ${count ?? 0} ${entity}${count > 1 ? 's' : ''} will be deleted`;
  },
  getActiveDocksFromWarehouse(warehouse) {
    return warehouse?.docks?.filter(dock => dock.isActive);
  },
  getLoadTypesFromActiveDocks(docks) {
    let selectedLoadTypes = [];
    docks?.forEach(dock => {
      if (dock.isActive) {
        selectedLoadTypes = [...selectedLoadTypes, ...dock.loadTypeIds];
      }
    });
    return Array.from(new Set(selectedLoadTypes));
  },
  getNumberGroupsFromString(str) {
    return str?.match(/[0-9.]*/g) ?? [];
  },
  getSizeUnitFromString(str) {
    return str?.match(/[a-zA-Z%]+/g) ?? [];
  },
  stringOnlyContainsNumbers(str) {
    return /[0-9.]*/.test(str);
  },
  stringContainsNoNumbers(str) {
    return !/\d/.test(str);
  },
  scrollFirstErrorIntoView(container = null, errorClass = 'error--text') {
    const elements = container
      ? container.getElementsByClassName(errorClass)
      : document.getElementsByClassName(errorClass);
    if (elements?.length > 0) {
      elements[0].scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  },
  /**
   * This is ugly but until I find a better way to control the shadow dom element styles for RARE style applications,
   * This will have to do...
   * @param shadowRootEl The shadow-root element
   * @param shadowDomClass Selector for element inside the shadow dom we apply the styles to
   * @param styles string of semicolon delimited styling rules
   */
  appendStyleToShadowDomEl(shadowRootEl, shadowDomSelector, styles) {
    let style = document.createElement('style');
    style.innerHTML = `${shadowDomSelector} {${styles}}`;
    shadowRootEl.shadowRoot?.appendChild(style);
  },
  appendStylesToShadowDomEls(shadowDomEls, shadowDomSelector, styles) {
    shadowDomEls.forEach(el => this.appendStyleToShadowDomEl(el, shadowDomSelector, styles));
  },
  getLargerAreaOfAddress({ city, state, country, zip }, includeZip = true) {
    let largerArea = `${city}, ${state ?? country}`;
    if (includeZip) {
      largerArea += ` ${zip}`;
    }
    return largerArea;
  },
  /**
   * Parses the sizeInput to get the value/unit
   */
  computeSize(sizeInput) {
    let value;
    let unit;
    let size;
    if (this.stringContainsNoNumbers(sizeInput)) {
      // Supports 'small', 'default', and 'large'
      size = SizingEnum[sizeInput];
    } else if (sizeInput.includes('font-size')) {
      // Supports any valid Miranda font-size token
      size = mirandaUtil.getTokenVal(sizeInput);
    } else {
      // Supports an manual size and unit i.e. 10px or 2.5rem
      size = sizeInput;
    }
    value = this.getSizeValue(size);
    unit = this.getSizeUnit(size);
    if (this.isSizeValid(value, unit)) {
      return {
        sizeValue: value,
        sizeUnit: unit
      };
    } else {
      return;
    }
  },
  isSizeValid(value, unit) {
    return this.stringOnlyContainsNumbers(value) && this.stringContainsNoNumbers(unit);
  },
  getSizeValue(size) {
    return this.getNumberGroupsFromString(size)?.[0];
  },
  getSizeUnit(size) {
    return this.getSizeUnitFromString(size)?.[0] ?? 'px';
  },
  validateValuesAgainstRules(val, rules) {
    const errorBucket = [];
    for (let index = 0; index < rules.length; index++) {
      const rule = rules[index];
      const valid = typeof rule === 'function' ? rule(val) : rule;

      if (valid === false || typeof valid === 'string') {
        errorBucket.push(valid || '');
      } else if (typeof valid !== 'boolean') {
        console.error(
          `Rules should return a string or boolean, received '${typeof valid}' instead`,
          this
        );
      }
    }
    return errorBucket;
  },
  /**
   * This allows you to attach an element to another element.  It defaults to below, but depending on the placement
   * and if it overflows the containerEl, it will reposition to stay inside
   * @param el The element being attached to
   * @param elToAttach The element to be attached
   * @param containerEl The container - if none provided, a dialog (if inside dialog) or window will be the fallback
   * @param elToAttachWidth Ability to give a specific width rather than rely on the element width from JS
   * @param padding Padding to be applied when repositioned
   */
  attachElToEl({ el, elToAttach, containerEl = null, elToAttachWidth = null, padding = 5 }) {
    const elCoords = el.getBoundingClientRect();
    const windowDimensions = {
      width: window.innerWidth,
      height: window.innerHeight,
      left: 0,
      right: window.innerWidth,
      top: 0,
      bottom: window.innerHeight
    };
    elToAttachWidth = elToAttachWidth ?? elToAttach.innerWidth;

    // Container el will be what is provided via the args with a fallback of a dialog, and finally the window dimensions
    containerEl = containerEl ?? el.closest('m-dialog')?.shadowRoot?.querySelector('dialog');
    const boundingClient = containerEl ? containerEl.getBoundingClientRect() : windowDimensions;

    // Overflow checks
    const rightOverflowPx = elCoords.left + elToAttachWidth - boundingClient.right;
    const bottomOverflowPx = boundingClient.bottom - (elCoords.bottom + elToAttachWidth);
    const hasTopOverflow = elCoords.top - elToAttachWidth < boundingClient.top;

    // Attach element, then adjust based on overflow checks
    elToAttach.style.top = `${elCoords.bottom}px`;
    elToAttach.style.left = `${elCoords.left}px`;

    if (rightOverflowPx > 0) {
      elToAttach.style.left = `${elCoords.left - rightOverflowPx - padding}px`;
    }
    if (bottomOverflowPx < 0) {
      if (hasTopOverflow) {
        elToAttach.style.top = `${padding}px`;
      } else {
        elToAttach.style.top = `${elCoords.top - elToAttachWidth - padding}px`;
      }
    }
  }
};

export function propValidatorHelper({
  value,
  isValid,
  componentName,
  propName,
  errorMessage,
  docsLink
}) {
  // TODO: Add ability to see incorrect props given
  if (!isValid) {
    console.error(
      `Component: ${componentName}.vue\nProp Name: ${propName} ${
        errorMessage ? `- ${errorMessage}` : ''
      }\nCurrent prop value: ${JSON.stringify(value)}.\nSee docs - ${
        docsLink ?? `http://localhost:6060/#${componentName.toLowerCase()}`
      }`
    );
  }

  return isValid;
}

export function validateV2DropDownOptionsProp(prop, componentName) {
  let isValid = true;
  const hasStrings = prop.every(val => isString(val));
  if (!hasStrings) {
    // Expects following schema: [ { label: 'option label', value: 'option value' } ]
    isValid = prop.every(obj => Object.keys(obj).every(key => ['label', 'value'].includes(key)));
  }

  return propValidatorHelper({
    prop,
    isValid,
    componentName: componentName,
    propName: 'options',
    message: ''
  });
}

export function storeBooleanInLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function retrieveBooleanFromLocalStorage(key) {
  const storedValue = localStorage.getItem(key);
  return storedValue === null ? null : JSON.parse(storedValue);
}

export function customLogoPath(warehouse, s3BaseUrl) {
  // Use warehouseLogo first, then orgLogo
  const orgLogo = warehouse?.org?.settings?.customLogo;
  const warehouseLogo = warehouse?.settings?.customLogo;
  const logo = warehouseLogo ?? orgLogo;

  return logo ? Core.fileKeyToDownloadUrl(s3BaseUrl, logo) : null;
}
