<template>
  <field-value label="Contact Info">
    <template #value>
      <m-stack gap="spacing-1">
        <m-group align="center">
          <v-icon size="16px">cellphone</v-icon>
          <v-link
            color="color-text-primary"
            :is-bold="false"
            v-if="warehouse.phone"
            :href="`tel:${warehouse.phone}`">
            {{ warehouse.phone }}
          </v-link>
          <no-content v-else></no-content>
        </m-group>
        <m-group align="flex-start" class="flex-nowrap nobreak">
          <v-icon size="16px">email-outline</v-icon>
          <v-link
            v-if="warehouse.email"
            color="color-text-primary"
            :is-bold="false"
            :href="`mailto:${warehouse.email}`">
            {{ warehouse.email }}
          </v-link>
          <no-content v-else></no-content>
        </m-group>
      </m-stack>
    </template>
  </field-value>
</template>
<script>
import { FieldValue, VIcon, NoContent, VLink } from '@satellite/components/v2';
import { propValidatorHelper } from '@satellite/plugins/util';
/**
 * Warehouse contact info
 * @displayName Warehouse Contact
 */
export default {
  name: 'WarehouseContact',
  components: {
    FieldValue,
    VIcon,
    NoContent,
    VLink
  },
  props: {
    /**
     * Partial Warehouse
     * @values { phone?: string, email?: string }
     */
    warehouse: {
      type: Object,
      required: true,
      validator(value) {
        const isValid = ['phone', 'email'].every(requiredKey => {
          return Object.keys(value).includes(requiredKey);
        });
        return propValidatorHelper({
          value,
          isValid: isValid,
          componentName: 'WarehouseContact',
          propName: 'warehouse',
          message: ''
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.warehouse-logo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $m-color-background-tertiary;
}
.nobreak {
  word-break: break-all;
}
</style>
