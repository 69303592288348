<template>
  <internal-page page-title="User settings" allow-overflow>
    <template #body>
      <m-stack>
        <collapsable-card :key="`personal-info-${cards.personalInfo.resetIterator}`">
          <template #title>
            <m-group align="center" gap="spacing-1">
              <v-icon size="15px">account</v-icon>
              <m-text variant="body-md-bold">Personal information</m-text>
            </m-group>
            <m-text variant="body-md" color="color-text-tertiary">
              Edit your name, country, timezone, or phone
            </m-text>
          </template>

          <template #body>
            <basic-form
              v-if="!loaders.personalInfo"
              ref="personal-info-form"
              :fields="personalFields"
              @submit="savePersonalInfo" />
            <v-loader v-else class="m-y-10" :is-loading="loaders.personalInfo">
              Updating personal information
            </v-loader>
          </template>

          <template #footer>
            <m-group gap="spacing-2">
              <primary-button
                :disabled="loaders.personalInfo"
                test-id="save-personal-info-btn"
                @click="triggerPersonalInfoSubmit">
                Save changes
              </primary-button>
              <secondary-button
                :disabled="loaders.personalInfo"
                test-id="close-personal-info-btn"
                @click="cancelPersonalInfo">
                Cancel
              </secondary-button>
            </m-group>
          </template>
        </collapsable-card>

        <collapsable-card :key="`password-${cards.password.resetIterator}`">
          <template #title>
            <m-group align="center" gap="spacing-1">
              <v-icon size="15px">lock</v-icon>
              <m-text variant="body-md-bold">Password</m-text>
            </m-group>
            <m-text variant="body-md" color="color-text-tertiary">
              Change your current password
            </m-text>
          </template>
          <template #body>
            <basic-form
              v-show="!loaders.password"
              ref="password-form"
              :fields="passwordFields"
              enable-field-update-events
              @update:new-password="validateNewPassword"
              @update:new-password-confirm="validateNewPassword"
              @submit="savePassword" />
            <v-loader v-show="loaders.password" class="m-y-10" :is-loading="loaders.password">
              Updating password
            </v-loader>
          </template>
          <template #footer>
            <m-group gap="spacing-2">
              <primary-button
                :disabled="loaders.password"
                test-id="save-password-btn"
                @click="triggerPasswordSubmit">
                Save new password
              </primary-button>
              <secondary-button
                :disabled="loaders.password"
                test-id="close-password-btn"
                @click="cancelPassword">
                cancel
              </secondary-button>
            </m-group>
          </template>
        </collapsable-card>

        <m-card>
          <m-card-body>
            <m-group class="p-y-0 p-x-1" align="center" justify="space-between">
              <m-stack gap="spacing-1" align="flex-start">
                <m-group align="center" gap="spacing-1">
                  <v-icon size="20px">warehouse</v-icon>
                  <m-text variant="body-md-bold">Company</m-text>
                </m-group>
                <m-text variant="body-md" color="color-text-tertiary">
                  {{ $me.company.name }}
                </m-text>
              </m-stack>
              <tertiary-button
                test-id="change-company-btn"
                trailing-icon="chevron-right"
                icon-size="1.5rem"
                @click="$router.push({ name: 'settings.company' })">
                Change company
              </tertiary-button>
            </m-group>
          </m-card-body>
        </m-card>
      </m-stack>
    </template>
  </internal-page>
</template>

<script>
import {
  VIcon,
  InternalPage,
  TertiaryButton,
  CollapsableCard,
  BasicForm,
  PrimaryButton,
  SecondaryButton,
  VLoader
} from '@satellite/components/v2';
import { isEqual } from 'lodash';

export default {
  components: {
    VIcon,
    InternalPage,
    TertiaryButton,
    CollapsableCard,
    BasicForm,
    PrimaryButton,
    SecondaryButton,
    VLoader
  },
  computed: {
    personalFields() {
      return {
        firstName: {
          component: 'text-field',
          group: 1,
          fieldProps: {
            label: 'First name',
            required: true,
            value: this.$me?.firstName,
            rules: this.$validator.rules.required(),
            placeholder: 'Enter your first name',
            testId: 'first-name-field'
          }
        },
        lastName: {
          component: 'text-field',
          group: 1,
          fieldProps: {
            label: 'Last name',
            required: true,
            value: this.$me.lastName,
            rules: this.$validator.rules.required(),
            placeholder: 'Enter your last name',
            testId: 'last-name-field'
          }
        },
        phone: {
          component: 'phone-number-field',
          fieldProps: {
            label: 'Phone number',
            required: false,
            value: this.$me.phone,
            testId: 'phone-number-field'
          }
        }
      };
    }
  },
  data() {
    return {
      loaders: {
        personalInfo: false,
        password: false
      },
      cards: {
        personalInfo: {
          resetIterator: 0
        },
        password: {
          resetIterator: 0
        }
      },
      passwordFields: {
        currentPassword: {
          component: 'password-field',
          fieldProps: {
            label: 'Current password',
            placeholder: 'Enter password',
            required: true,
            testId: 'update-password-current-field',
            value: '',
            bypassRequirements: true
          }
        },
        newPassword: {
          component: 'password-field',
          fieldProps: {
            label: 'New password',
            testId: 'update-password-new-field',
            value: '',
            shouldShowRequirements: true
          }
        },
        newPasswordConfirm: {
          component: 'password-field',
          fieldProps: {
            label: 'Repeat password',
            placeholder: 'Enter password again for confirmation',
            required: true,
            testId: 'update-password-confirm-field',
            value: ''
          }
        }
      }
    };
  },
  methods: {
    triggerPersonalInfoSubmit() {
      this.$refs['personal-info-form'].submitForm();
    },
    async savePersonalInfo({ fields }) {
      this.loaders.personalInfo = true;
      await this.services.user
        .updateUser(this.$me.id, fields)
        .then(user => {
          this.loaders.personalInfo = false;
          this.$store.commit('Auth/setMe', { ...this.$me, ...user });
        })
        .finally(() => (this.loaders.personalInfo = false));
    },
    cancelPersonalInfo() {
      this.$refs['personal-info-form'].resetForm();
      this.cards.personalInfo.resetIterator++;
    },
    triggerPasswordSubmit() {
      this.$refs['password-form'].submitForm();
    },
    async savePassword({ fields }) {
      if (fields.newPassword !== fields.newPasswordConfirm) {
        this.$set(
          this.passwordFields.newPasswordConfirm.fieldProps,
          'error',
          'The passwords do not match'
        );
        this.$refs['password-form'].validateForm();
        return;
      }
      this.loaders.password = true;
      await axios
        .post('user/change-password', fields)
        .then(response => {
          this.notify(response.data.message);
          this.cancelPassword();
        })
        .finally(() => (this.loaders.password = false));
    },
    cancelPassword() {
      this.$refs['password-form'].resetForm();
      this.cards.password.resetIterator++;
    },
    validateNewPassword() {
      if (this.$refs['password-form'].hasBeenValidated) {
        const formValues = this.$refs['password-form'].getFieldValues();
        let errorMessage = '';
        if (!isEqual(formValues['newPassword'], formValues['newPasswordConfirm'])) {
          errorMessage = 'The passwords do not match';
        }
        this.$set(this.passwordFields.newPasswordConfirm.fieldProps, 'error', errorMessage);
        this.$refs['password-form'].validateForm();
      }
    }
  }
};
</script>
