<template>
  <dialog-base
    v-bind="$attrs"
    :value="value"
    @input="updateVal"
    size="medium"
    :should-display-close-button="!isLoading">
    <template #header>
      <m-text variant="heading-sm-bold">Cancel appointment</m-text>
    </template>
    <template #body>
      <m-group v-if="!isLoading">
        <m-text variant="body-md">
          This action will make the time slot available for others to book
        </m-text>
        <basic-form
          ref="cancellation-form"
          :fields="fields"
          @submit="submit"
          @update:can-submit="setCanSubmit"></basic-form>
      </m-group>
      <v-loader v-else :is-loading="isLoading">Cancelling appointment</v-loader>
    </template>
    <template #actions>
      <m-group class="flex-nowrap p-t-4" gap="spacing-2">
        <template>
          <tertiary-button
            test-id="keep-appointment-btn"
            @click="updateVal(false)"
            :disabled="isLoading">
            Keep appointment
          </tertiary-button>
          <error-button
            @click="$refs['cancellation-form'].submitForm()"
            test-id="cancel-appointment-btn"
            :disabled="isLoading || !canSubmit">
            Confirm cancellation
          </error-button>
        </template>
      </m-group>
    </template>
  </dialog-base>
</template>

<script>
import {
  DialogBase,
  ErrorButton,
  TertiaryButton,
  VLoader,
  BasicForm
} from '@satellite/components/v2';
import dialogMixin from '@satellite/components/v2/mixins/dialogMixin';

/**
 * Shown when cancelling appointment
 * @displayName Cancel Appointment Dialog
 */
export default {
  name: 'CancelAppointmentDialog',
  mixins: [dialogMixin],
  components: {
    BasicForm,
    DialogBase,
    ErrorButton,
    TertiaryButton,
    VLoader
  },
  props: {
    appointment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      cancelReason: '',
      canSubmit: true,
      fields: {
        cancelReason: {
          component: 'text-area',
          fieldProps: {
            label: 'Reason for cancellation\n',
            required: true,
            value: '',
            placeholder: 'Let the warehouse know why you are cancelling',
            testId: 'cancellation-reason-field',
            isCompact: true
          }
        }
      }
    };
  },
  methods: {
    setCanSubmit(canSubmit) {
      this.canSubmit = canSubmit;
    },
    async submit({ fields }) {
      this.isLoading = true;
      this.mixpanel.track(this.mixpanel.events.ACTION.CANCEL_APPOINTMENT, {
        appointmentId: this.appointment.id,
        'Warehouse ID': this.appointment.warehouse.id,
        'Warehouse Name': this.appointment.warehouse.name
      });

      await this.services.appointment
        .cancelAppointment(
          this.appointment,
          fields.cancelReason,
          {},
          { suppressNotification: true }
        )
        .catch(res => {
          this.notify(res.response?.data?.message || res, 'error');
        })
        .then(appointment => {
          if (appointment?.id) {
            this.notify('Your appointment was cancelled', 'info');
            this.$emit('update:appointment', appointment);
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.updateVal(false);
        });
    }
  }
};
</script>
