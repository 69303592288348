<template>
  <dialog-base
    :value="value"
    @input="updateVal"
    size="small"
    has-custom-close-logic
    @custom-close="redirectToMain">
    <template #header>
      <m-group gap="spacing-1">
        <v-icon size="25px" color="color-warning-100">information</v-icon>
        <m-text variant="heading-md-bold" color="color-warning-100">Preview mode</m-text>
      </m-group>
    </template>
    <template #body>
      <m-text variant="body-sm" color="color-text-tertiary">
        You're on Preview Mode, so the appointment was not created. The scheduling user will see a
        confirmation after booking.
      </m-text>
      <m-stack class="m-t-4 m-b-2">
        <m-text variant="body-md-bold">{{ appointmentDateTime }}</m-text>
        <warehouse-header :warehouse="warehouse"></warehouse-header>
        <field-value
          label="Confirmation number"
          :value="appointment?.confirmationNumber"
          :copy-content="appointment?.confirmationNumber" />
        <warehouse-address :warehouse="warehouse"></warehouse-address>
      </m-stack>
    </template>
  </dialog-base>
</template>

<script>
import { DialogBase, VIcon, FieldValue } from '@satellite/components/v2';
import dialogMixin from '@satellite/components/v2/mixins/dialogMixin';
import WarehouseHeader from '@/components/elements/warehouse/WarehouseHeader.vue';
import WarehouseAddress from '@/components/elements/warehouse/WarehouseAddress.vue';
import { LuxonDateTimeFormats, formatDateTimeWithMilitarySupport } from '@satellite/../nova/core';

/**
 * Shown when creating an appointment
 * @displayName Appointment Submission Dialog
 */
export default {
  name: 'AppointmentSubmissionDialog',
  mixins: [dialogMixin],
  components: {
    WarehouseHeader,
    DialogBase,
    VIcon,
    FieldValue,
    WarehouseAddress
  },
  data() {
    return {
      warehouseId: null
    };
  },
  props: {
    /**
     * Warehouse object
     */
    warehouse: {
      type: Object,
      required: true
    },
    /**
     * Appointment object
     */
    appointment: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    }
  },
  methods: {
    redirectToMain() {
      if (this.warehouseId) {
        this.$router.push({ name: 'warehouse.details', params: { warehouseId: this.warehouseId } });
      } else {
        this.close();
      }
    }
  },
  computed: {
    appointmentDateTime() {
      let formattedDateTime = '';
      if (this.appointment) {
        formattedDateTime = formatDateTimeWithMilitarySupport(
          this.appointment?.start,
          this.warehouse.timezone,
          LuxonDateTimeFormats.ShortDayDateSlashed12HourZone,
          this.$isMilitaryTimeEnabled(this.warehouse),
          LuxonDateTimeFormats.ShortDayDateSlashed24HourZone
        );
      }
      return formattedDateTime;
    }
  },
  beforeMount() {
    this.warehouseId = this.$previewWarehouseId || sessionStorage.getItem('previewWarehouseId');
  }
};
</script>
