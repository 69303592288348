import utilityMixinBase from '@satellite/components/v2/mixins/utilityMixinBase';

/**
 * Utility Mixin
 * @mixin
 * @displayName Global Utility Mixin
 */
const utilityMixin = {
  computed: {
    ...utilityMixinBase.computed
  },
  data() {
    return {
      ...utilityMixinBase.data()
    };
  },
  methods: {
    ...utilityMixinBase.methods
  }
};

export default utilityMixin;
