<template>
  <internal-page allow-overflow>
    <template v-slot:header>
      <page-header title="My appointments">
        <template v-slot:header-actions>
          <big-number-boxes
            class="big-number-box-container"
            v-if="hasAppointments"
            :big-numbers="bigNumbers"
            :selected="selectedBigNumber"
            @selected="handleBigNumberClick"
            @deselected="selectedBigNumber = ''"></big-number-boxes>
          <tertiary-button
            v-if="hasAppointments"
            leading-icon="download"
            test-id="export-appointments-btn"
            @click="$eventHub.$emit('export-carrier-appointments')"
            size="large">
            Export
          </tertiary-button>
        </template>
      </page-header>
    </template>
    <template v-slot:body>
      <div style="flex: 1">
        <appointment-list
          v-if="hasAppointments"
          @update:appointments="updateBigNumbers"
          :status-filter="selectedBigNumber"
          @removeStatusFilter="selectedBigNumber = ''"></appointment-list>
        <image-message
          :image-path="successSvg"
          image-alt="Truck pulling up to a dock"
          v-else-if="loaded">
          <m-text variant="body-md-bold">Start booking appointments to see them here</m-text>
          <m-text variant="body-sm">
            You will be able to view and manage your appointments on this page
          </m-text>
          <primary-button
            test-id="no-appointments-book-appointment-btn"
            @click="$router.push({ name: 'warehouse.select' })"
            class="book-appointment-btn m-t-3">
            Book appointment
          </primary-button>
        </image-message>
      </div>
    </template>
    <template v-slot:footer></template>
  </internal-page>
</template>

<script>
import {
  InternalPage,
  PageHeader,
  TertiaryButton,
  BigNumberBoxes,
  ImageMessage,
  PrimaryButton
} from '@satellite/components/v2';
import AppointmentList from '@/components/elements/appointment/AppointmentList.vue';
import successSvg from '@satellite/assets/success.svg';
import { camelCaseToHumanReadable, AppointmentStatus } from '@satellite/../nova/core';

export default {
  components: {
    PrimaryButton,
    ImageMessage,
    AppointmentList,
    TertiaryButton,
    BigNumberBoxes,
    PageHeader,
    InternalPage
  },
  data() {
    return {
      hasAppointments: false,
      bigNumbers: [],
      filters: {
        search: ''
      },
      successSvg,
      selectedBigNumber: '',
      tableHeaders: [
        {
          text: 'Date and Time',
          value: 'start',
          sortable: false
        },
        {
          text: 'Warehouse',
          value: 'warehouse',
          sortable: false
        },
        {
          text: 'City',
          value: 'city',
          sortable: false
        },
        {
          text: 'Confirmation Number',
          value: 'confirmation_number',
          sortable: false
        },
        {
          text: 'Reference Number',
          value: 'reference_number',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false
        }
      ],
      appointmentCounts: {},
      loaded: false
    };
  },
  methods: {
    handleBigNumberClick(selected) {
      this.selectedBigNumber = selected;
    },
    updateBigNumbers(data) {
      const sortOrder = [
        AppointmentStatus.Requested,
        AppointmentStatus.Scheduled,
        AppointmentStatus.Arrived,
        AppointmentStatus.InProgress,
        AppointmentStatus.Completed,
        AppointmentStatus.Cancelled
      ];

      const statusCounts = data.metadata.statusCounts;
      delete statusCounts[AppointmentStatus.NoShow];

      this.bigNumbers = Object.keys(statusCounts)
        .sort((a, b) => {
          return sortOrder.indexOf(a) - sortOrder.indexOf(b);
        })
        .map(key => {
          return {
            title: camelCaseToHumanReadable(key),
            number: data.metadata.statusCounts[key]
          };
        });
    }
  },
  async mounted() {
    this.hasAppointments =
      (await this.services.appointment.getAppointments({ limit: 1 })).length > 0;
    this.loaded = true;
    this.mixpanel.track(this.mixpanel.events.VIEW.MY_APPOINTMENTS_PAGE);
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  m-header-actions {
    align-items: center;
  }
}
.book-appointment-btn {
  max-width: 200px;
  align-self: center;
}
.big-number-box-container {
  @media (max-width: $tabletBreakpoint) {
    display: none;
  }
}
</style>
