var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog-base',_vm._b({attrs:{"value":_vm.value,"size":"medium"},on:{"input":_vm.updateVal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('m-text',{attrs:{"variant":"heading-md-bold"}},[_vm._v("Provide ETA")])]},proxy:true},{key:"body",fn:function(){return [(!_vm.isLoading)?_c('m-stack',{attrs:{"gap":"spacing-3"}},[_c('m-text',{attrs:{"variant":"heading-sm-bold"}},[_vm._v(" Appointment time: "+_vm._s(_vm.extendedAppointment.getReadableDate())+" - "+_vm._s(_vm.extendedAppointment.getReadableStartTime())+" "+_vm._s(_vm.extendedAppointment.getTimezoneAbbreviation())+" ")]),_c('m-group',{staticClass:"flex-nowrap p-r-20",attrs:{"gap":"spacing-2"}},[_c('v-icon',{attrs:{"size":"20px","color":"color-text-tertiary"}},[_vm._v("alert")]),_c('m-text',{attrs:{"variant":"body-sm","color":"color-text-tertiary"}},[_vm._v(" Providing an ETA notifies the warehouse but does not reschedule the appointment or bypass late charges and fines ")])],1),_c('m-group',{attrs:{"gap":"spacing-2","align":"center"}},[_c('v-icon',{attrs:{"size":"20px","color":"color-text-tertiary"}},[_vm._v("clock-outline")]),_c('m-text',{attrs:{"variant":"body-sm","color":"color-text-tertiary"}},[_vm._v(" ETA must be within 24 hours of the appointment start time ")])],1),_c('m-group',{staticClass:"flex-nowrap eta-input",attrs:{"align":"center"}},[_c('time-stamp-field',{attrs:{"rule":_vm.$validator.rules.required(),"required":"","min-date":_vm.minDate,"max-date":_vm.maxDate,"test-id":"eta-time-stamp","validation-iterator":_vm.validationIterator,"label":"ETA","is-military-time-enabled":_vm.extendedAppointment.isMilitaryTimeEnabled,"timezone":_vm.extendedAppointment.warehouse.timezone,"placeholder":"07:00","rules":[
            ..._vm.$validator.rules.required(),
            ..._vm.$validator.rules.isTimeWithin24Hours(
              _vm.extendedAppointment.start,
              'ETA must be within 24 hours of the appointment start time'
            )
          ]},on:{"update:error":hasError => _vm.updateFieldError(hasError, 'eta')},model:{value:(_vm.etaISO),callback:function ($$v) {_vm.etaISO=$$v},expression:"etaISO"}}),_c('div',{staticClass:"m-t-6 tag-wrapper"},[_c('m-tag',{attrs:{"size":"large","variant":_vm.etaTagVariant}},[_vm._v(" "+_vm._s(_vm.getReadableEtaStatus(_vm.etaCondition))+" ")])],1)],1),_c('text-field',{attrs:{"test-id":"eta-reason","label":"Reason","required":"","validation-iterator":_vm.validationIterator,"rules":_vm.$validator.rules.required()},on:{"update:error":hasError => _vm.updateFieldError(hasError, 'reason')},model:{value:(_vm.etaReason),callback:function ($$v) {_vm.etaReason=$$v},expression:"etaReason"}})],1):_c('v-loader',{attrs:{"is-loading":_vm.isLoading}},[_vm._v("Updating the ETA")])]},proxy:true},{key:"actions",fn:function(){return [_c('m-group',{staticClass:"flex-nowrap p-t-4",attrs:{"gap":"spacing-2"}},[[_c('tertiary-button',{attrs:{"disabled":_vm.isLoading,"test-id":"cancel-eta-btn"},on:{"click":function($event){return _vm.updateVal(false)}}},[_vm._v(" Cancel ")]),_c('primary-button',{attrs:{"disabled":_vm.isLoading || !_vm.etaReason || !_vm.etaISO,"test-id":"create-eta-btn"},on:{"click":_vm.createEta}},[_vm._v(" Provide ETA ")])]],2)]},proxy:true}])},'dialog-base',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }