<template>
  <section class="appointment-list" :class="`appointment-list-${_uid}`">
    <div class="header" ref="sticky-header">
      <m-group align="center">
        <text-field
          v-model="filters.searchStr"
          placeholder="Search"
          class="appointment-search-field m-r-5"
          test-id="my-appointments-search-field">
          <span slot="leading">
            <v-icon size="22px" color="color-text-tertiary" class="flex">magnify</v-icon>
          </span>
        </text-field>
        <date-range-picker
          ref="appointment-list-date-range-picker"
          use-custom-trigger
          v-model="filters.dateRange"
          test-id="appointment-list-date-range-picker"></date-range-picker>
        <button-toggle-group
          id="toggle-buttons"
          v-model="namedDateRange"
          :buttons="[
            { value: 'custom', label: customDateRangeDisplay, icon: 'calendar' },
            { value: '7_days', label: '7 Days' },
            { value: 'today', label: 'Today' },
            { value: 'all', label: 'All' }
          ]"></button-toggle-group>
        <v-spacer></v-spacer>
        <m-text v-if="!loading" variant="body-md" color="color-text-tertiary">
          {{ totalItems }} {{ resultText }}
        </m-text>
      </m-group>

      <m-group justify="space-between" align="center" class="p-t-2">
        <m-tag v-if="statusFilter">
          {{ statusFilter }}
          <v-icon size="12" class="m-l-2 close-icon" @click="$emit('removeStatusFilter')">
            close
          </v-icon>
        </m-tag>
        <tertiary-button
          v-if="statusFilter"
          @click="$emit('removeStatusFilter')"
          leading-icon="close"
          test-id="appointment-list-clear-filters-btn">
          Clear Filters
        </tertiary-button>
      </m-group>
    </div>
    <div class="table-wrapper">
      <data-table-base
        :headers="headers"
        :items="appointments"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 30]
        }"
        item-key="id"
        :loading="loading"
        fixed-header
        data-testid="appointment-list-table"
        :custom-filter="searchRows"
        @click:row="item => handleRowClick(item)"
        :server-items-length="totalItems"
        class="clickable-rows appointment-list-table">
        <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ value }">
          <span
            :key="header.value"
            :inner-html.prop="
              value | highlight(regexSafeSearch, null, null, searchHighlightClass)
            "></span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <appointment-status-tag :status="item.status"></appointment-status-tag>
        </template>

        <template v-slot:[`item.actions`]>
          <div class="row-actions">
            <v-icon size="25px" color="color-text-tertiary">chevron-right</v-icon>
          </div>
        </template>

        <template #loading>
          <v-loader :is-loading="loading" class="m-y-12">Loading appointments</v-loader>
        </template>

        <template v-slot:[`item.start`]="{ item }">
          <span>
            {{
              getTimeInWarehouseTimezone(
                item,
                `${novaCore.LuxonDateTimeFormats.LongDateShortMonth} @ ${novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM} - ${novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset}`,
                `${novaCore.LuxonDateTimeFormats.LongDateShortMonth} @ ${novaCore.LuxonDateTimeFormats.Extended24HrTime} - ${novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset}`
              )
            }}
          </span>
        </template>
        <template v-slot:no-data>
          <div class="p-10">
            <no-results v-if="initialDataFetched">
              <m-text variant="md-bold">We couldn't find any appointments</m-text>
              <m-text variant="body-sm">
                You can try different filters or clearing all of them
              </m-text>
              <template v-slot:footer>
                <primary-button
                  test-id="no-results-clear-filters-btn"
                  size="small"
                  @click="clearFilters">
                  Clear Filters
                </primary-button>
              </template>
            </no-results>
          </div>
        </template>
      </data-table-base>
    </div>
  </section>
</template>

<script>
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import exportAppointmentsMixin from '@satellite/components/mixins/exportAppointmentsMixin';
import appointmentMixin from '@/components/mixins/appointmentMixin';
import {
  ButtonToggleGroup,
  DataTableBase,
  TextField,
  VIcon,
  AppointmentStatusTag,
  DateRangePicker,
  TertiaryButton,
  NoResults,
  PrimaryButton,
  VSpacer,
  VLoader
} from '@satellite/components/v2';
import { DateTime } from 'luxon';
import { LuxonDateTimeFormats } from '@satellite/../nova/core';

export default {
  components: {
    PrimaryButton,
    NoResults,
    TertiaryButton,
    TextField,
    ButtonToggleGroup,
    DataTableBase,
    VIcon,
    AppointmentStatusTag,
    DateRangePicker,
    VSpacer,
    VLoader
  },
  mixins: [dataListMixin, appointmentMixin, exportAppointmentsMixin],
  props: {
    showExportButton: {
      type: Boolean,
      required: false,
      default: true
    },
    buildHeadersFn: {
      type: Function,
      required: false,
      default: null
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 15
    },
    statusFilter: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      appointments: [],
      sortBy: ['start'],
      filters: {
        searchStr: null,
        dateRange: [],
        status: null
      },
      expanded: [],
      loading: false,
      totalItems: 0,
      hasPageUpdated: false,
      selectedDateButtonFilter: null,
      namedDateRange: '7_days',
      searchHighlightClass: 'blue lighten-3',
      initialDataFetched: false,
      lastSearchStr: null
    };
  },
  computed: {
    resultText() {
      return this.totalItems === 1 ? 'result' : 'results';
    },
    rangeValues() {
      const dateTime = DateTime.fromJSDate(new Date());
      const inputFormat = LuxonDateTimeFormats.DateDashed;
      return {
        custom: [dateTime.toFormat(inputFormat), dateTime.toFormat(inputFormat)],
        today: [dateTime.toFormat(inputFormat), dateTime.toFormat(inputFormat)],
        '7_days': [
          dateTime.toFormat(inputFormat),
          dateTime.plus({ days: 7 }).toFormat(inputFormat)
        ],
        all: []
      };
    },
    excelFileName() {
      return `${this.$warehouse?.name || 'opendock'}_appointments.xlsx`;
    },
    headers() {
      if (this.buildHeadersFn) {
        return this.buildHeadersFn();
      }

      return [
        {
          text: 'Date and time',
          value: 'start',
          searchable: true
        },
        {
          text: 'Warehouse',
          value: 'dock.warehouse.name',
          searchable: true,
          align: ''
        },
        {
          text: 'City',
          value: 'dock.warehouse.city',
          searchable: true
        },
        {
          text: 'Confirmation Number',
          value: 'confirmationNumber',
          searchable: true
        },
        {
          text: this.$consolidatedSettings.referenceNumberDisplayName,
          value: 'refNumber',
          searchable: true
        },
        {
          text: 'Status',
          value: 'status',
          searchable: true
        },
        {
          text: '',
          value: 'actions',
          searchable: false
        }
      ];
    },
    warehouse() {
      // This is used by appointmentMixin
      return this.$warehouse;
    },
    customDateRangeDisplay() {
      let text = 'Custom';
      if (this.filters.dateRange?.length > 1 && this.namedDateRange === 'custom') {
        const start = DateTime.fromFormat(
          this.filters.dateRange[0],
          LuxonDateTimeFormats.DateDashed
        ).toFormat(LuxonDateTimeFormats.MonthDayYearSlashed);
        const end = DateTime.fromFormat(
          this.filters.dateRange[1],
          LuxonDateTimeFormats.DateDashed
        ).toFormat(LuxonDateTimeFormats.MonthDayYearSlashed);
        text = `${start} - ${end}`;
      }
      return text;
    }
  },
  methods: {
    getCustomFieldValue(customField, appointment) {
      if (!customField.value) {
        return '----';
      }

      return this.novaCore.getCustomFieldFormattedValue(customField, {
        [this.novaCore.CustomFieldType.Timestamp]: {
          timezone: appointment.dock.warehouse.timezone,
          formatAsMilitary: this.$isMilitaryTimeEnabled(this.$org)
        }
      });
    },
    async getData() {
      this.appointments = [];
      if (this.shouldGetData(this.filters) && !this.hasPageUpdated) {
        this.loading = true;
        let query = this.getQueryBase();
        if (this.filters.dateRange.length === 2) {
          query.s.start = {
            $between: [
              momentjs(this.filters.dateRange[0]).toDate(),
              momentjs(this.filters.dateRange[1]).endOf('day').toDate()
            ]
          };
        }
        if (this.filters.status) {
          query.s.status = { $eq: this.filters.status.replace(/\s+/g, '') };
        }
        if (this.lastSearchStr !== this.filters.searchStr) {
          this.mixpanel.track(this.mixpanel.events.ACTION.SEARCH_APPOINTMENTS, {
            'Search String': this.filters.searchStr
          });
        }
        this.lastSearchStr = this.filters.searchStr;
        try {
          const appointmentResp = await this.services.appointment.getAppointments(query, {
            includeMetaData: true,
            joins: ['dock||name,id', 'dock.warehouse', 'loadType||name,direction']
          });
          if (appointmentResp) {
            this.appointments = appointmentResp.data.data;
            this.totalItems = appointmentResp.data.total;
            this.$emit('update:appointments', {
              appointments: appointmentResp.data.data,
              metadata: appointmentResp.data.metadata
            });
          }
        } finally {
          this.loading = false;
          this.initialDataFetched = true;
        }
      }
      this.hasPageUpdated = false;
    },
    shouldGetData(filters) {
      return filters.dateRange.length !== 1;
    },
    filteredCustomFields(event) {
      if (event) {
        return this.novaCore
          .updateCustomFieldsFromWarehouse(event.customFields, event.dock.warehouse, true)
          .filter(field => !field.hiddenFromCarrier);
      }
      return [];
    },
    handleExportClick() {
      this.mixpanel.track(this.mixpanel.events.ACTION.EXPORT_APPOINTMENTS);
      this.exportToExcel(this.appointments);
    },
    handleRowClick(appointment) {
      this.$router.push({
        name: 'appointment.details',
        params: { appointmentId: appointment.id }
      });
    },
    clearFilters() {
      this.filters.searchStr = '';
      this.namedDateRange = '7_days';
      this.$emit('removeStatusFilter');
    },
    adjustTableHeaderTop() {
      const tableHeader = document.querySelector(
        `.appointment-list-${this._uid} .v-data-table-header`
      );
      tableHeader.style.top = `${
        this.$refs['sticky-header'].offsetHeight - tableHeader.offsetHeight + 24
      }px`;
    }
  },
  async mounted() {
    this.filters.dateRange = this.rangeValues[this.namedDateRange];
    this.$eventHub.$on(['export-carrier-appointments'], this.handleExportClick);
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.adjustTableHeaderTop();
      });
    });
  },
  destroyed() {
    this.$eventHub.$off(['export-carrier-appointments'], this.handleExportClick);
  },
  watch: {
    'filters.searchStr'() {
      if (!this.filters.searchStr) {
        this.expanded = [];
      }
      this.debounceInput();
    },
    namedDateRange(selected) {
      this.$nextTick(() => {
        if (selected === 'custom') {
          this.$refs['appointment-list-date-range-picker'].toggleDatePicker(true);
        } else if (selected !== null) {
          this.filters.dateRange = this.rangeValues[this.namedDateRange];
          this.options.page = 1;
        }
      });
    },
    statusFilter() {
      this.filters.status = this.statusFilter;
      this.mixpanel.track(this.mixpanel.events.ACTION.FILTER_APPOINTMENTS_BY_STATUS, {
        'Status Selected': this.statusFilter || 'None'
      });
      this.debounceInput();
      this.$nextTick(() => {
        this.adjustTableHeaderTop();
      });
    },
    'filters.dateRange'() {
      this.mixpanel.track(this.mixpanel.events.ACTION.FILTER_APPOINTMENTS_BY_DATE_RANGE, {
        'Date Range Selected': this.namedDateRange
      });
      if (this.filters.namedDateRange === 'custom' && this.filters.dateRange?.length > 1) {
        this.debounceInput();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.appointment-list {
  display: flex;
  flex-direction: column;
}
.header {
  padding: $m-spacing-3;
  background: $m-color-background-primary;
  border: 1px solid var(--line-divider, #e6edf2);
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  position: sticky;
  top: -24px;
  z-index: 10;
}
.appointment-search-field {
  max-width: 350px;
}

.close-icon {
  cursor: pointer;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
}

.clickable-rows {
  cursor: pointer;
}

.appointment-list-table {
  background-color: $m-color-background-primary !important;
  flex: 1;
}

.table-wrapper {
  border: 1px solid var(--line-divider, #e6edf2);
  border-top: none;
  border-radius: 0 0 5px;
}

::v-deep {
  .v-data-table .v-data-table__wrapper {
    overflow: unset;
  }

  .v-data-table-header {
    position: sticky;
    top: 45px;
    z-index: 1;

    th {
      background: $m-color-background-secondary !important;
      border-top: 1px solid var(--line-divider, #e6edf2);
      border-bottom: 1px solid var(--line-divider, #e6edf2) !important;
    }
  }

  tr {
    background-color: #fff;
  }

  .v-data-footer {
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 1;
    width: 100%;
  }
}
</style>
