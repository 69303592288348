<template>
  <m-sidebar class="sidebar-component" gap="none" min-content-width="600px">
    <m-box slot="sidebar" id="sidebar" class="flex flex-column">
      <header class="heading">
        <v-logo></v-logo>
        <m-text
          variant="heading-md-bold"
          as="h2"
          color="color-text-secondary-inverted"
          class="uppercase">
          Scheduling Portal
        </m-text>
      </header>

      <m-stack gap="spacing-6" class="benefits-list hide-mobile">
        <m-stack gap="none" class="list-item">
          <m-group align="center" padding="none">
            <v-icon color="white" size="font-size-7">view-grid-plus</v-icon>
            <m-text variant="heading-md-bold" as="h3" color="color-text-secondary-inverted">
              One-stop-shop
            </m-text>
          </m-group>
          <m-text variant="body-md" as="p" color="color-text-secondary-inverted">
            No more keeping a list of URLs, user names, and passwords for every shipper.
          </m-text>
        </m-stack>

        <m-stack gap="none" class="list-item">
          <m-group align="center" padding="none">
            <v-icon color="white" size="font-size-7">lightning-bolt</v-icon>
            <m-text variant="heading-md-bold" as="h3" color="color-text-secondary-inverted">
              Quick &amp; Convenient
            </m-text>
          </m-group>
          <m-text variant="body-md" as="p" color="color-text-secondary-inverted">
            Schedule appointments online without making a phone call or waiting for email responses.
          </m-text>
        </m-stack>

        <m-stack gap="none" class="list-item">
          <m-group align="center" padding="none">
            <v-icon color="white" size="font-size-7">meditation</v-icon>
            <m-text variant="heading-md-bold" as="h3" color="color-text-secondary-inverted">
              Easy for drivers
            </m-text>
          </m-group>
          <m-text variant="body-md" as="p" color="color-text-secondary-inverted">
            Confirmation emails with appointment date, time, address, check-in instructions, and
            contact information for the warehouse.
          </m-text>
        </m-stack>
      </m-stack>

      <v-spacer class="hide-mobile"></v-spacer>
      <copyright-text class="hide-mobile" color="color-text-inverted"></copyright-text>
      <auth-links color="color-text-inverted" class="hide-mobile"></auth-links>
    </m-box>

    <m-box slot="content" id="main-content">
      <m-stack id="registration-form-container">
        <registration-form></registration-form>
        <copyright-text class="hide-mid-desktop"></copyright-text>
        <auth-links class="hide-mid-desktop"></auth-links>
      </m-stack>
    </m-box>
  </m-sidebar>
</template>

<script>
import { VIcon, VSpacer, AuthLinks, CopyrightText, VLogo } from '@satellite/components/v2';
import RegistrationForm from '@/modules/auth/components/RegistrationForm.vue';

export default {
  components: {
    CopyrightText,
    AuthLinks,
    RegistrationForm,
    VIcon,
    VSpacer,
    VLogo
  },
  mounted() {
    this.mixpanel.track(this.mixpanel.events.VIEW.REGISTRATION_PAGE);
  }
};
</script>

<style scoped lang="scss">
#sidebar {
  background-size: cover;
  background-image: url('@/../public/images/auth-sidebar.png');
  background-position: center;
  background-color: var(--primary-primary-100, #033);
  background-blend-mode: multiply;
  border-radius: 0;
  opacity: 0.85;
  width: 600px;
  padding: $m-spacing-8;

  @media (max-width: $midDesktopBreakpoint) {
    width: 424px;
    padding: $m-spacing-4;
  }
}
.sidebar-component {
  height: 100%;
  @media (max-width: $smallDesktopBreakpoint) {
    height: auto;
  }
}
.heading {
  padding-top: $m-spacing-10;
  padding-bottom: $m-spacing-20;
  display: flex;
  flex-direction: row;
  gap: $m-spacing-4;

  @media (max-width: $midDesktopBreakpoint) {
    padding: $m-spacing-2;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: $m-spacing-2;
  }
}
#main-content {
  @media (max-width: $midDesktopBreakpoint) {
    min-width: 100% !important;
  }
}
#registration-form-container {
  width: 525px;
  margin: 0 auto;
  justify-content: safe center;
  padding: $m-spacing-8;
  height: 100%;

  @media (max-width: 1200px) {
    padding: $m-spacing-4;
  }

  @media (max-width: $midDesktopBreakpoint) {
    width: 100%;
    max-width: 600px;
    height: auto;
  }
}
.benefits-list {
  padding-right: $m-spacing-30;

  @media (max-width: $midDesktopBreakpoint) {
    padding-right: $m-spacing-10;
  }
}
.list-item {
  padding-right: $m-spacing-6;
}

.hide-mobile {
  @media (max-width: $midDesktopBreakpoint) {
    display: none;
  }
}
.hide-mid-desktop {
  @media (min-width: $midDesktopBreakpoint) {
    display: none;
  }
}
</style>
