<template>
  <dialog-base :value="value" @input="value = !value">
    <template v-slot:body>
      <m-stack class="p-t-1" align="center">
        <v-icon size="48px" color="color-warning-100">alert-circle</v-icon>
        <m-text variant="heading-sm-bold">Sorry, we couldn't create your account</m-text>
        <span>
          <m-text variant="body-sm">Please try filling out the registration form again.</m-text>
          <m-text variant="body-sm">
            If you still run into problems,
            <v-link :href="$supportUrl">contact our support team</v-link>
          </m-text>
        </span>
      </m-stack>
    </template>
    <template v-slot:actions>
      <primary-button
        is-block
        @click="$emit('confirm')"
        leading-icon="refresh"
        test-id="re-register-btn">
        Try Again
      </primary-button>
    </template>
  </dialog-base>
</template>

<script>
import { VIcon, DialogBase, PrimaryButton, VLink } from '@satellite/components/v2';
import dialogMixin from '@satellite/components/v2/mixins/dialogMixin';

/**
 * Shown when creating an account throws an error
 * @displayName Account Creation Error Dialog
 */
export default {
  name: 'AccountCreationErrorDialog',
  mixins: [dialogMixin],
  components: {
    DialogBase,
    PrimaryButton,
    VIcon,
    VLink
  }
};
</script>
