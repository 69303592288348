import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

export default function initSentry(vue, routes, config) {
  if (config.sentry_dsn) {
    Sentry.init({
      vue,
      environment: config.server_env,
      dsn: config.sentry_dsn,
      release: config.nova_version,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(routes),
          tracingOrigins: [config.neutron_url, /^\//]
        })
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: config.sentry_traces_sample_rate ?? 1,
      // Redact sensitive information
      beforeSend(event) {
        function redactSensitiveData(str) {
          if (!str) {
            return str;
          }

          return str.replace(
            /[a-zA-Z0-9_=]{36}\.[a-zA-Z0-9_=]{51}\.[a-zA-Z0-9_\-+/=]{43}/u,
            'JWT_REDACTED'
          );
        }

        if (event.request) {
          event.request.url = redactSensitiveData(event.request.url);
        }

        if (event.exception) {
          event.exception.values = event.exception.values?.map(exception => ({
            ...exception,
            value: redactSensitiveData(exception.value)
          }));
        }

        return event;
      }
    });
  }
}
