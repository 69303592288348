<template>
  <m-stack gap="none" class="height-100">
    <m-group class="display-tablet flex width-100 flex-0" align="center" justify="space-between">
      <tertiary-button test-id="get-company-help-btn" leading-icon="help-circle" icon-size="15px">
        Get help
      </tertiary-button>
      <v-link href="https://safer.fmcsa.dot.gov/CompanySnapshot.aspx" target="_blank">
        Look up USDOT number
      </v-link>
    </m-group>
    <m-tabs active-tab="name" @m-tab-change="handleTabChange">
      <m-group justify="space-between" align="center">
        <div class="tabs">
          <m-tab name="name" data-testid="company-search-tab-name">
            <span>
              <span class="display-desktop">Search by</span>
              company name
            </span>
          </m-tab>
          <m-tab name="usdot" data-testid="company-search-tab-usdot">
            <span>
              <span class="display-desktop">Search by</span>
              USDOT number
            </span>
          </m-tab>
        </div>
        <v-link
          class="display-desktop"
          href="https://safer.fmcsa.dot.gov/CompanySnapshot.aspx"
          target="_blank">
          Look up USDOT number
        </v-link>
      </m-group>
      <m-tab-panel for-tab="usdot" :key="`${renderKey}-searchUsdot`">
        <text-field
          v-model="searchStrings.usdot"
          label=""
          placeholder="Start typing to search by USDOT number"
          test-id="company-usdot-search">
          <span slot="leading">
            <v-icon size="22px" color="color-text-tertiary" class="flex">magnify</v-icon>
          </span>
        </text-field>
      </m-tab-panel>
      <m-tab-panel for-tab="name" :key="`${renderKey}-searchName`">
        <text-field
          v-model="searchStrings.name"
          label=""
          placeholder="Start typing to search by company name"
          test-id="company-name-search">
          <span slot="leading">
            <v-icon size="20px" color="color-primary-60" class="m-t-0-5 is__block">magnify</v-icon>
          </span>
        </text-field>
      </m-tab-panel>
    </m-tabs>

    <!-- TODO the selected row is supposed to highlight, but it is difficult to do with datatable -->
    <data-table-base
      v-if="companies?.length"
      class="hide-rows-per-page company-table mb-2"
      :items="companies"
      :headers="tableHeaders"
      :mobile-breakpoint="0"
      overflow-x="auto"
      :server-items-length="totalItems"
      :items-per-page="options.itemsPerPage"
      @click:row="item => setSelectedCompany(item.id)">
      <template v-slot:[`item.select`]="{ item }">
        <div @click="setSelectedCompany(item.id)" data-testId="select-company-radio-btn">
          <v-icon size="24px">
            {{ item.id === selectedCompany ? 'radiobox-marked' : 'radiobox-blank' }}
          </v-icon>
        </div>
      </template>
    </data-table-base>

    <v-loader :is-loading="isLoading" v-if="isLoading">Loading companies</v-loader>

    <template v-else>
      <m-group
        gap="none"
        justify="space-between"
        id="select-company-footer"
        v-if="hasSearchValue && companies.length">
        <m-group align="center" justify="space-between" class="width-100">
          <m-text>
            Couldn't find your company?
            <link-button
              test-id="create-new-company-footer-button"
              @click="$emit('show-new-company-dialog')">
              Create a new carrier company
            </link-button>
          </m-text>
          <primary-button
            test-id="save-company-btn"
            @click="saveCompanySelection"
            :disabled="!selectedCompany">
            {{ saveButtonLabel }}
          </primary-button>
        </m-group>
      </m-group>
      <no-results v-else-if="!companies.length && hasSearched">
        <m-stack gap="none">
          <m-text variant="body-sm" v-if="!$slots.message">Please try a different search or</m-text>
          <link-button test-id="create-new-company-btn" @click="$emit('show-new-company-dialog')">
            create a new company
          </link-button>
        </m-stack>
      </no-results>
    </template>
  </m-stack>
</template>

<script>
import { debounce } from 'debounce';
import renderMixin from '@satellite/components/mixins/renderMixin';
import {
  TextField,
  VIcon,
  PrimaryButton,
  DataTableBase,
  VLoader,
  NoResults,
  LinkButton,
  TertiaryButton,
  VLink
} from '@satellite/components/v2';

export default {
  mixins: [renderMixin],
  components: {
    DataTableBase,
    TextField,
    VIcon,
    PrimaryButton,
    VLoader,
    NoResults,
    LinkButton,
    TertiaryButton,
    VLink
  },
  props: {
    isRegistrationStep: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      searchStrings: {
        usdot: '',
        name: ''
      },
      companies: [],
      tableHeaders: [
        { text: 'Select', value: 'select', sortable: false },
        { text: 'Company Name', value: 'name', sortable: false },
        { text: 'USDOT Number', value: 'usdot', sortable: false }
      ],
      selectedCompany: null,
      options: {
        itemsPerPage: 5,
        page: 1
      },
      isLoading: false,
      initialSearch: true,
      totalItems: 0,
      hasSearched: false
    };
  },
  computed: {
    hasSearchValue() {
      return this.searchStrings.usdot?.length || this.searchStrings.name?.length;
    },
    saveButtonLabel() {
      return this.isRegistrationStep ? 'Save and start using Opendock' : 'Save company';
    }
  },
  methods: {
    getCompanies: debounce(async function () {
      const searchMinLength = 2;
      if (
        this.searchStrings.name.length < searchMinLength &&
        this.searchStrings.usdot.length < searchMinLength
      ) {
        this.companies = [];
        this.initialSearch = true;
        return;
      }
      this.isLoading = true;

      const response = await this.services.company
        .getCompanies(this.getQueryParams(), {
          includeMetaData: true
        })
        .finally(() => {
          this.isLoading = false;
          this.initialSearch = false;
          this.hasSearched = true;
        });
      this.totalItems = response.data.total;
      this.companies = response.data.data;
    }, 350),
    getQueryParams() {
      const params = {
        page: this.options.page ?? 1,
        limit: this.options.itemsPerPage
      };
      if (this.searchStrings.usdot.length) {
        params.s = { usdot: { $eqL: this.searchStrings.usdot.toLowerCase() } };
      } else if (this.searchStrings.name.length) {
        params.s = { name: { $contL: this.searchStrings.name.toLowerCase() } };
      }
      return params;
    },
    async setSelectedCompany(companyId) {
      this.selectedCompany = companyId;
    },

    handleTabChange() {
      this.searchStrings.usdot = '';
      this.searchStrings.name = '';
      this.rerender();
    },
    async saveCompanySelection() {
      await this.services.user.updateUser(
        this.$me.id,
        { companyId: this.selectedCompany },
        { includeMetaData: true },
        { suppressNotification: true }
      );
      await this.$store.dispatch('Auth/getMe');
    }
  },
  watch: {
    searchStrings: {
      handler() {
        this.hasSearched = false;
        this.options.page = 1;
        this.getCompanies();
      },
      deep: true
    },
    'options.page'() {
      this.getCompanies();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .company-table {
    tr {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
#select-company-footer {
  flex: 1;
  align-items: flex-end;
}
#not-found-container {
  max-width: 30%;
  min-width: 320px;
}

m-tabs {
  --m-tabs-border-width: 0;
}
m-tab-panel {
  padding: $m-spacing-6 0;
}

:root {
  m-tabs {
    --m-tabs-border-width: 0 !important;
  }
}

.tabs {
  @media (max-width: $tabletBreakpoint) {
    width: 100%;
    text-align: left;
  }
}
</style>
