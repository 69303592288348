<template>
  <section>
    <m-grid class="value-grid" v-bind="$attrs" v-if="hasCustomFormData">
      <field-value
        v-for="{ id, label, value } in customFormData"
        :key="id"
        :label="label"
        :value="value"></field-value>
    </m-grid>
    <m-text variant="body-md" color="color-text-tertiary" v-else>Not Confirmed</m-text>
  </section>
</template>

<script>
import customFormsDataMixin from '@satellite/modules/custom_forms/customFormsDataMixin';
import { FieldValue } from '@satellite/components/v2';

export default {
  name: 'CustomFormsData',
  components: { FieldValue },
  mixins: [customFormsDataMixin],
  async mounted() {
    await this.getCustomFormData();
  }
};
</script>
