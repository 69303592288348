<script>
/**
 * This is hidden from the docs because the StatusTimelineWrapper should be used instead of this directly.
 * @displayName Appointment Status Timeline
 */
import { AppointmentStatus } from '@satellite/../nova/core';
import { VStepper } from '@satellite/components/v2';

export default {
  name: 'AppointmentStatusTimeline',
  extends: VStepper,
  props: {
    /**
     * Active and completed item color - expects a status property i.e. --status-scheduled-base
     * These can be found in the status_theme.scss partial in Satellite
     */
    color: {
      type: String,
      required: false
    },
    /**
     * The appointment with a status timeline
     */
    appointment: {
      type: Object,
      required: true
    }
  },
  mounted() {
    document.getElementById(`stepper-${this._uid}`).classList.add(this.color);
    if (this.computedColor) {
      // If a color is passed as a prop, apply it to the step circle
      this.util.appendStylesToShadowDomEls(
        document.querySelectorAll(`.stepper-${this._uid} m-step[completed]`),
        '.circle',
        `background-color:${this.computedColor} !important;`
      );
    }

    if (this.computedActiveStep === this.ALL_STEPS_COMPLETED_TEXT) {
      this.$nextTick(async () => {
        this.$nextTick(() => {
          const lastStep = document.querySelector(`.stepper-${this._uid} m-step:last-child`);
          const icon = lastStep.shadowRoot.querySelector('m-icon');
          if (
            [AppointmentStatus.Cancelled, AppointmentStatus.NoShow].includes(
              this.appointment.status
            )
          ) {
            icon.setAttribute('name', 'close');
          }

          icon.style.setProperty(
            '--m-icon-color',
            'rgba(var(--m-color-text-inverted, 252, 252, 255), 1)'
          );

          lastStep.removeAttribute('aria-current');
        });
      });
    } else {
      this.util.appendStylesToShadowDomEls(
        document.querySelectorAll(
          `.stepper-${this._uid} m-step[name='${this.computedActiveStep}']`
        ),
        '.circle',
        `background-color: rgba(var(--m-color-neutral-40, 193, 206, 217), 1) !important;`
      );
    }

    this.util.appendStylesToShadowDomEls(
      document.querySelectorAll(`.stepper-${this._uid} m-step`),
      '.label',
      `color: var(--m-color-text-primary) !important;font-weight:400 !important;`
    );

    this.util.appendStylesToShadowDomEls(
      document.querySelectorAll(`m-steps.stepper-${this._uid}`),
      '.steps-container',
      `@media(max-width:1280px) {flex-direction:column;flex-flow:column !important;align-items:flex-start !important;gap:30px !important;}`
    );
  }
};
</script>

<style lang="scss" scoped>
m-step {
  flex: initial;
  min-width: 110px;

  &:before {
    display: none;
  }
  &:after {
    background-color: var(--status-cancelled-base);
  }
  &[completed]:not(:has(~ [completed])):after {
    background-color: $m-color-text-disabled !important;
  }
  &[completed]:before {
    display: none !important;
  }
  &[completed] + m-step:before {
    display: block;
    width: 50%;
    z-index: 1;
    right: calc(100% + var(--m-steps-circle-size) / 2 + var(--m-steps-circle-margin-inline-end));
  }
}

.color-text-disabled {
  m-step {
    &:after,
    &:before {
      background-color: $m-color-text-disabled;
    }
  }
}
.requested {
  m-step {
    &[completed]:after,
    &[completed] + m-step:before {
      background-color: var(--status-requested-base);
    }
  }
}
.scheduled {
  m-step {
    &[completed]:after,
    &[completed] + m-step:before {
      background-color: var(--status-scheduled-base);
    }
  }
}
.arrived {
  m-step {
    &[completed]:after,
    &[completed] + m-step:before {
      background-color: var(--status-arrived-base);
    }
  }
}
.inprogress {
  m-step {
    &[completed]:after,
    &[completed] + m-step:before {
      background-color: var(--status-inprogress-base);
    }
  }
}
.completed {
  m-step {
    &[completed]:after,
    &[completed] + m-step:before {
      background-color: var(--status-completed-base);
    }
  }
}
.cancelled {
  m-step {
    &[completed]:after,
    &[completed] + m-step:before {
      background-color: var(--status-cancelled-base);
    }
  }
}

m-steps {
  $mobileStatusTimeline: 1280px;
  @media (max-width: $mobileStatusTimeline) {
    flex-direction: column;
    justify-content: flex-start;
  }

  m-step {
    @media (max-width: $mobileStatusTimeline) {
      flex-direction: row;
      text-align: left !important;
      * {
        font-size: 14px !important;
      }
    }
    &:before {
      @media (max-width: $mobileStatusTimeline) {
        display: none !important;
      }
    }
    &:after {
      $barHeight: 16px;
      @media (max-width: $mobileStatusTimeline) {
        width: 2px;
        height: $barHeight;
        left: 11px;
        top: calc(100% + (#{$barHeight} / 2));
      }
    }
  }
}
</style>
