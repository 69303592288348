import { isReserve } from '@satellite/../nova/core';

const entityTermMap = {
  Appointment: 'Appointment',
  AuditLog: 'Audit Log',
  Billing: 'Billing',
  Carrier: 'Carrier Contact',
  Company: 'Company',
  Dock: 'Dock',
  Feedback: 'Feedback',
  LoadType: 'Load Type',
  Org: 'Organization',
  User: 'User',
  Warehouse: 'Warehouse'
};

export const NotificationClassEnum = Object.freeze({
  error: 'app-error',
  info: 'app-info',
  success: 'app-success',
  warning: 'app-warning'
});

const notificationConfigs = {
  success: {
    icon: 'mdi-check-circle',
    appClasses: NotificationClassEnum.success,
    iconColor: 'color-success-60',
    actionComponent: 'primary-button'
  },
  error: {
    icon: 'mdi-alert-circle',
    appClasses: NotificationClassEnum.error,
    iconColor: 'color-danger-60',
    actionComponent: 'error-button'
  },
  warning: {
    icon: 'mdi-alert',
    appClasses: NotificationClassEnum.warning,
    iconColor: 'color-warning-100',
    actionComponent: 'warning-button'
  },
  info: {
    icon: 'mdi-information',
    appClasses: NotificationClassEnum.info,
    iconColor: 'color-accent-60',
    actionComponent: 'tertiary-button'
  }
};

export default {
  getEntityTerm(entity) {
    const entityName = entity?.entity ?? '';
    let entityTerm = entityTermMap[entityName] || '';

    // TODO: This logic is not ideal, as it knows about an specific type
    // of appointment. For the future, use some kind of generic "alias" coming
    // from the backend.
    if (entityTerm && isReserve(entity.data)) {
      entityTerm += ' (Reserve)';
    }

    return entityTerm;
  },
  getNotificationConfig(type) {
    return notificationConfigs[type];
  }
};
