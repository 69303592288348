<template>
  <dialog-base
    v-bind="$attrs"
    :value="value"
    @input="updateVal"
    size="large"
    :should-display-close-button="!isLoading">
    <template #header>
      <m-text variant="heading-md-bold">Edit details</m-text>
    </template>
    <template #body>
      <appointment-details-form
        fields-are-full-width
        v-if="!isLoading"
        ref="appointment-details-form"
        @submit="submit"
        :appointment="extendedAppointment"
        :warehouse="warehouse"
        :custom-fields="customFields" />
      <v-loader v-else :is-loading="isLoading">Updating appointment</v-loader>
    </template>
    <template #actions>
      <m-group class="flex-nowrap p-t-4" gap="spacing-2">
        <template>
          <secondary-button test-id="cancel-btn" @click="updateVal(false)" :disabled="isLoading">
            Cancel
          </secondary-button>
          <primary-button @click="triggerSubmit" test-id="save-changes-btn" :disabled="isLoading">
            Save changes
          </primary-button>
        </template>
      </m-group>
    </template>
  </dialog-base>
</template>

<script>
import { DialogBase, PrimaryButton, SecondaryButton, VLoader } from '@satellite/components/v2';
import dialogMixin from '@satellite/components/v2/mixins/dialogMixin';
import customFieldsMixin from '@satellite/components/v2/mixins/customFieldsMixin';
import AppointmentDetailsForm from '@/components/elements/appointment/AppointmentDetailsForm.vue';
import { ExtendedAppointment } from '@satellite/modules/v2/appointments/ExtendedAppointment';
import { propValidatorHelper } from '@satellite/plugins/util';
import { updateCustomFieldsFromWarehouse } from '@satellite/../nova/core';

/**
 * Shown when editing appointment fields
 * @displayName Edit Appointment Dialog
 */
export default {
  name: 'EditAppointmentDialog',
  mixins: [dialogMixin, customFieldsMixin],
  components: {
    DialogBase,
    PrimaryButton,
    SecondaryButton,
    AppointmentDetailsForm,
    VLoader
  },
  props: {
    extendedAppointment: {
      type: ExtendedAppointment,
      required: true,
      validator(value) {
        return propValidatorHelper({
          value,
          isValid: value instanceof ExtendedAppointment,
          componentName: 'EditAppointmentDialog',
          propName: 'extendedAppointment',
          message: ''
        });
      }
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    warehouse() {
      return this.extendedAppointment.warehouse;
    },
    customFields() {
      return updateCustomFieldsFromWarehouse(
        this.extendedAppointment.customFields,
        this.extendedAppointment.warehouse,
        true
      );
    }
  },
  methods: {
    triggerSubmit() {
      // TODO: Think of how to make this cleaner for devs
      this.$refs['appointment-details-form'].submissionIterator++;
    },
    async submit({ fields }) {
      this.isLoading = true;
      fields.customFields = Object.values(
        this.getCustomFieldsWithValues(fields.customFields, this.customFields)
      );
      this.mixpanel.track(this.mixpanel.events.ACTION.EDIT_APPOINTMENT_DETAILS, {
        appointmentId: this.extendedAppointment.id,
        'Warehouse ID': this.extendedAppointment.warehouse.id,
        'Warehouse Name': this.extendedAppointment.warehouse.name
      });
      await this.services.appointment
        .updateAppointment(this.extendedAppointment.id, fields)
        .catch(res => {
          this.notify(res.response?.data?.message || res, 'error');
        })
        .then(response => {
          if (Array.isArray(response.customFields)) {
            response.customFields = updateCustomFieldsFromWarehouse(
              response.customFields.filter(cf => !cf.hiddenFromCarrier),
              this.extendedAppointment.warehouse,
              true
            );
          }
          if (response?.id) {
            this.$emit('update:appointment', response);
            this.updateVal(false);
          }
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>
