<template>
  <dialog-base :value="value" @input="updateVal">
    <template v-slot:body>
      <m-stack class="p-t-1" align="center">
        <v-icon size="48px" color="color-warning-100">message-alert</v-icon>
        <m-text variant="heading-sm-bold">This email address is already being used</m-text>
        <m-text variant="body-sm">
          Sign in to use your existing account, or register again with a different email address
        </m-text>
      </m-stack>
    </template>
    <template v-slot:actions>
      <secondary-button @click="close" test-id="register-again-btn">
        Register Again
      </secondary-button>
      <primary-button @click="confirm" leading-icon="login" test-id="sign-in-btn">
        Sign In
      </primary-button>
    </template>
  </dialog-base>
</template>

<script>
import { VIcon, DialogBase, SecondaryButton, PrimaryButton } from '@satellite/components/v2';
import dialogMixin from '@satellite/components/v2/mixins/dialogMixin';

/**
 * Shown on duplicate email error when creating an account
 * @displayName Duplicate Email Error Dialog
 */
export default {
  name: 'DuplicateEmailErrorDialog',
  mixins: [dialogMixin],
  components: {
    DialogBase,
    SecondaryButton,
    PrimaryButton,
    VIcon
  },
  methods: {
    updateVal(value) {
      /**
       * Emits input event
       * @property {Boolean} - is dialog displayed
       */
      this.$emit('input', value);
    }
  }
};
</script>
