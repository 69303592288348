// Vue
import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';

// APP
import App from './App.vue';
import config from './config/config';
import Routes from '@/routes.js';
import store from './store';

// Mixins
import localStorageMixin from '@satellite/components/mixins/localStorageMixin';
import utilityMixin from '@/components/mixins/utilityMixin';
import mappedStates from './components/mixins/mappedStates';
import mappedGetters from './components/mixins/mappedGetters';
import mixpanelMixin from './components/mixins/mixpanelMixin';
import settingsMixin from './components/mixins/settingsMixin';

// Plugins
import util from '@satellite/plugins/util';
import mirandaUtil from '@satellite/plugins/MirandaUtil';
import ConfirmDialogPlugin from '@satellite/plugins/ConfirmDialog/ConfirmDialogPlugin';
import AxiosPlugin from '@satellite/plugins/v2/AxiosPlugin';
import { loadFonts } from '@/plugins/webfontloader';
import vuetify from './plugins/vuetifyPlugin';

import validation from '@satellite/plugins/Validator';
import initSentry from '@satellite/plugins/sentry';
import Notifications from 'vue-notification';
import velocity from 'velocity-animate';
import Hotjar from 'vue-hotjar';
import featureFlagPlugin from './plugins/featureFlagPlugin';

// Deps
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import moment from 'moment-timezone';
import * as Core from '@satellite/../nova/core';
import VueMask from 'v-mask';
import '@loadsmart/miranda-wc';

// Helpers
import SocketPlugin from '@satellite/plugins/SocketPlugin';

import warehouseService from '@satellite/services/warehouse-service';
import userService from '@satellite/services/user-service';
import dockService from '@satellite/services/dock-service';
import companyService from '@satellite/services/company-service';
import loadtypeService from '@satellite/services/loadtype-service';
import appointmentService from '@satellite/services/appointment-service';
import carrierService from '@satellite/services/carrier-service';

// Init Plugins
window.momentjs = moment;
AxiosPlugin.init(null, 'Luna');
ConfirmDialogPlugin.init(Vue);
loadFonts();

// Hotjar
if (config.hotjar_site_id) {
  Vue.use(Hotjar, {
    id: config.hotjar_site_id
  });
}

// MODULES
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueMask);
Vue.use(Notifications, { velocity });
Vue.use(featureFlagPlugin);

// Vue Prototypes
Vue.prototype.util = util;
Vue.prototype.mirandaUtil = mirandaUtil;
Vue.prototype.$eventHub = new Vue(); // Global event bus
Vue.prototype.novaCore = Core;
Vue.prototype.$validator = new Vue(validation);
Vue.prototype.config = config;

// Services
Vue.prototype.services = {
  warehouse: warehouseService,
  user: userService,
  dock: dockService,
  company: companyService,
  loadtype: loadtypeService,
  appointment: appointmentService,
  carrier: carrierService
};

// Vue Global Mixins
Vue.mixin(utilityMixin);
Vue.mixin(localStorageMixin);
Vue.mixin(mappedStates);
Vue.mixin(mappedGetters);
Vue.mixin(mixpanelMixin);
Vue.mixin(settingsMixin);

initSentry(Vue, Routes, config);

let main = new Vue({
  components: {},
  router: Routes,
  store,
  vuetify,
  render: h => h(App),
  mounted() {
    this.mixpanel.init();
    this.sockets = new SocketPlugin(this);
    store.$app = this;
    this.$store.commit('Settings/setSettingsMetadata', this.novaCore.EntitySettings);
    this.$store.dispatch('Auth/setInitialAccessToken');
    this.$store.commit('Auth/setPreviewWarehouseId', sessionStorage.getItem('previewWarehouseId'));
    this.$nextTick(async () => {
      await this.$store.dispatch('App/getNeutronVersion');
    });
  }
}).$mount('#app');

export default main;
