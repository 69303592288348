<template>
  <internal-page allow-overflow>
    <template #header>
      <page-header :title="pageTitle" :has-back-button="!isRegistrationStep">
        <template #header-actions>
          <v-link
            v-if="isRegistrationStep"
            href="https://community.loadsmart.com/hc/en-us/community/topics/14812876253715-OpenDock"
            target="_blank">
            <v-icon size="16px" color="color-text-link">help-circle</v-icon>
            Get Help
          </v-link>
        </template>
      </page-header>
    </template>
    <template v-slot:body>
      <m-stack class="height-100">
        <m-text variant="heading-sm">
          Let's check if your company is already registered with us. If not, you will be able to
          create a new company.
        </m-text>
        <company-search
          :is-registration-step="isRegistrationStep"
          @companySelected="saveSelectedCompany"
          @show-new-company-dialog="dialogs.companyCreateDialog = true"></company-search>
        <create-company-dialog
          v-model="dialogs.companyCreateDialog"
          @close="dialogs.companyCreateDialog = false"
          @companySelected="saveSelectedCompany"></create-company-dialog>
      </m-stack>
    </template>
  </internal-page>
</template>

<script>
import CompanySearch from '@/modules/auth/components/CompanySearch.vue';
import CreateCompanyDialog from '@/components/elements/auth/dialogs/CreateCompanyDialog.vue';
import { VIcon, InternalPage, PageHeader, VLink } from '@satellite/components/v2';

export default {
  components: { CreateCompanyDialog, CompanySearch, VIcon, InternalPage, PageHeader, VLink },
  props: {
    isRegistrationStep: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    pageTitle() {
      return this.isRegistrationStep ? 'Select Company' : 'Change company';
    }
  },
  data() {
    return {
      dialogs: {
        companyCreateDialog: false
      },
      selectedCompany: null,
      mounted: false
    };
  },
  methods: {
    async saveSelectedCompany(companyId) {
      const response = await this.services.user.updateUser(
        this.$me.id,
        { companyId },
        { includeMetaData: true }
      );
      await this.$store.dispatch('Auth/getMe');
      if (response?.status === 200) {
        if (this.isRegistrationStep) {
          await this.$router.replace({
            name: 'warehouse.select'
          });
          return;
        }
        await this.$router.push({
          name: 'settings'
        });
      }
    }
  },
  watch: {
    $me: {
      handler(newMe, oldMe) {
        const redirect = () => {
          if (this.isRegistrationStep) {
            this.$router.replace({ name: 'warehouse.select' });
            return;
          }
          this.$router.push({ name: 'settings' });
        };

        if (this.mounted && newMe?.companyId) {
          redirect();
        }

        // Initial Load
        if (newMe?.id && !oldMe) {
          if (newMe.companyId && this.isRegistrationStep) {
            this.$router.replace({ name: 'warehouse.select' });
            return;
          }
          this.mounted = true;
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="scss"></style>
