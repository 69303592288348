<template>
  <dialog-base
    data-testid="appointment-submission-dialog"
    :value="value"
    @input="value = !value"
    :should-display-header="!isLoading"
    has-custom-close-logic
    @custom-close="$router.push({ name: 'warehouse.select' })"
    size="small">
    <template #header>
      <m-group gap="spacing-1">
        <v-icon size="25px" :color="headerColor">{{ headerIcon }}</v-icon>
        <m-text variant="heading-md-bold" :color="headerColor">
          Appointment {{ appointmentAction }}
        </m-text>
      </m-group>
    </template>
    <template #body>
      <m-stack class="p-y-8" v-if="isLoading">
        <v-loader :is-loading="isLoading"></v-loader>
        <m-stack align="center" gap="none">
          <m-text variant="body-md-bold">We're sending your request</m-text>
          <m-text variant="body-md">This won't take long. Thank you for waiting</m-text>
        </m-stack>
      </m-stack>
      <m-stack v-else>
        <m-text variant="body-md" v-if="isRequested">
          Once the warehouse confirms your appointment, its status will change to “Scheduled”
        </m-text>
        <m-text variant="heading-sm-bold">{{ appointmentDateTime }}</m-text>
        <warehouse-header :warehouse="warehouse"></warehouse-header>
        <field-value
          label="Confirmation number"
          :value="appointment?.confirmationNumber"
          :copy-content="appointment?.confirmationNumber" />
        <warehouse-address :warehouse="warehouse"></warehouse-address>
      </m-stack>
    </template>
    <template #actions>
      <m-group class="flex-nowrap p-t-4" gap="spacing-2">
        <template v-if="!isLoading">
          <secondary-button
            @click="
              $router.replace({ name: 'warehouse.details', params: { warehouseId: warehouse.id } })
            "
            test-id="book-another-btn">
            Book another appointment
          </secondary-button>
          <primary-button
            @click="
              $router.replace({
                name: 'appointment.details',
                params: { appointmentId: appointment.id }
              })
            "
            test-id="see-details-btn">
            See all details
          </primary-button>
        </template>
      </m-group>
    </template>
  </dialog-base>
</template>

<script>
import {
  DialogBase,
  PrimaryButton,
  VLoader,
  VIcon,
  FieldValue,
  SecondaryButton
} from '@satellite/components/v2';
import dialogMixin from '@satellite/components/v2/mixins/dialogMixin';
import WarehouseHeader from '@/components/elements/warehouse/WarehouseHeader.vue';
import WarehouseAddress from '@/components/elements/warehouse/WarehouseAddress.vue';
import {
  LuxonDateTimeFormats,
  formatDateTimeWithMilitarySupport,
  AppointmentStatus
} from '@satellite/../nova/core';

/**
 * Shown when creating an appointment
 * @displayName Appointment Submission Dialog
 */
export default {
  name: 'AppointmentSubmissionDialog',
  mixins: [dialogMixin],
  components: {
    WarehouseHeader,
    DialogBase,
    PrimaryButton,
    VLoader,
    VIcon,
    FieldValue,
    WarehouseAddress,
    SecondaryButton
  },
  props: {
    /**
     * Loading state
     */
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Warehouse object
     */
    warehouse: {
      type: Object,
      required: true
    },
    /**
     * Appointment object
     */
    appointment: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    }
  },
  computed: {
    headerColor() {
      return this.isRequested ? 'color-accent-60' : 'color-success-100';
    },
    headerIcon() {
      return this.isRequested ? 'clock-outline' : 'check';
    },
    isRequested() {
      return this.warehouse?.settings?.appointmentCreationStatus === AppointmentStatus.Requested;
    },
    appointmentAction() {
      return this.isRequested ? 'requested' : 'booked';
    },
    appointmentDateTime() {
      let formattedDateTime = '';
      if (this.appointment) {
        formattedDateTime = formatDateTimeWithMilitarySupport(
          this.appointment?.start,
          this.warehouse.timezone,
          LuxonDateTimeFormats.ShortDayDateSlashed12HourZone,
          this.$isMilitaryTimeEnabled(this.warehouse),
          LuxonDateTimeFormats.ShortDayDateSlashed24HourZone
        );
      }
      return formattedDateTime;
    }
  }
};
</script>

<style lang="scss" scoped></style>
