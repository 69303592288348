import Vue from 'vue';
import Vuex from 'vuex';

import navigation from './modules/navigation';
import app from './modules/app';
import satelliteApp from '@satellite/store/app';
import { synchronizeFeatureFlagsContext } from '@/plugins/featureFlagPlugin';

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production'

// Import all modules from the modules folder
function loadLocalStores() {
  const context = require.context('@/modules', true, /store.js$/i);
  const modules = context
    .keys()
    .map(context)
    .map(m => m.default);
  return modules;
}

function loadSatelliteStores() {
  const context = require.context('@satellite/modules', true, /store.js$/i);
  const v2Context = require.context('@satellite/modules/v2', true, /store.js$/i);
  const v1Modules = context
    .keys()
    .map(context)
    .map(m => m.default);
  const v2Modules = v2Context
    .keys()
    .map(context)
    .map(m => m.default);
  return [...v1Modules, ...v2Modules];
}

const modules = {};
loadSatelliteStores().forEach(module => {
  modules[module.name] = module;
});
loadLocalStores().forEach(module => {
  modules[module.name] = modules[module.name] ? joinStores(modules[module.name], module) : module;
});

function joinStores(existingStore, newStore) {
  let store = {};
  Object.keys(existingStore).map(storeKey => {
    store[storeKey] = { ...existingStore[storeKey], ...newStore[storeKey] };
  });

  return store;
}

modules.navigation = navigation;
modules.App = joinStores(satelliteApp, app);

const store = new Vuex.Store({
  modules: modules
  // strict: debug,
  // plugins: debug ? [createLogger()] : []
});

// Properties on the Vuex store
store.modules = () => modules;

synchronizeFeatureFlagsContext(store);
export default store;
