<template>
  <div class="height-100">
    <m-stack v-if="isLoading" class="height-100" justify="center" align="center">
      <v-loader is-loading>Loading warehouse</v-loader>
    </m-stack>
    <internal-page v-else-if="warehouse" page-title="Book appointment" allow-overflow>
      <template v-slot:header>
        <page-header :title="warehouse.name" :has-back-button="!$previewWarehouseId">
          <template v-slot:header-actions>
            <secondary-button
              v-if="!$isMrPreview"
              leading-icon="star"
              test-id="add-to-favorites-btn"
              @click="toggleFavorite">
              {{ isWarehouseFavorited ? 'Remove from favorites' : 'Add to favorites' }}
            </secondary-button>
            <primary-button @click="bookWarehouse" test-id="book-warehouse-btn">
              Book appointment
            </primary-button>
          </template>
        </page-header>
      </template>
      <template v-slot:body>
        <sidebar-cards>
          <template v-slot:sidebar-content>
            <warehouse-header :warehouse="warehouse"></warehouse-header>
            <warehouse-address :warehouse="warehouse"></warehouse-address>
            <warehouse-contact :warehouse="warehouse"></warehouse-contact>

            <m-stack gap="spacing-3" v-if="warehouse.amenities.length > 0">
              <m-text variant="body-md">Amenities available</m-text>
              <m-grid class="amenities-grid" gap="spacing-1" v-if="warehouse.amenities.length > 0">
                <m-tag v-for="(amenity, index) in warehouse.amenities" :key="`amenity-${index}`">
                  {{ amenity }}
                </m-tag>
              </m-grid>
            </m-stack>

            <hours-of-operation-list
              data-testId="warehouse-hoops"
              :enable-military-time="warehouse.settings.enableMilitaryTime"
              :schedule="warehouse.schedule ?? DEFAULT_SCHEDULE"
              :timezone="warehouse.timezone"></hours-of-operation-list>
          </template>
          <template v-slot:main-content>
            <m-stack gap="spacing-1">
              <m-text variant="heading-sm-bold">Instructions</m-text>
              <m-text>
                <section v-if="warehouse.instructions" v-html="warehouse.instructions"></section>
                <no-content v-else></no-content>
              </m-text>
            </m-stack>

            <m-stack gap="spacing-1" v-if="warehouse.ppeRequirements.length > 0">
              <m-text variant="heading-sm-bold">PPE and other equipment</m-text>
              <m-group class="m-t-5">
                <template v-if="warehouse.ppeRequirements.length > 0">
                  <template v-for="(ppeRequirement, index) in warehouse.ppeRequirements">
                    <m-stack gap="spacing-2" align="center" :key="`ppe-requirement-${index}`">
                      <img
                        height="50"
                        width="50"
                        :src="`/images/warehouse/${getPPERequirementIcon(ppeRequirement)}.png`" />
                      <m-text variant="body-sm" :key="`ppe-requirement=${index}`">
                        {{ ppeRequirement }}
                      </m-text>
                    </m-stack>
                  </template>
                </template>
              </m-group>
            </m-stack>

            <m-stack gap="spacing-1" v-if="getYardMapPath(warehouse.settings)">
              <m-text variant="heading-sm-bold">Yard Map</m-text>
              <template>
                <m-text variant="body-md">
                  This image was provided by the warehouse. You can click on it to see the full
                  image.
                </m-text>
                <a
                  :href="getYardMapPath(warehouse.settings)"
                  rel="noopener noreferrer"
                  target="_blank">
                  <img
                    class="yard-map-img"
                    alt="Yard Map"
                    :src="getYardMapPath(warehouse.settings)" />
                </a>
              </template>
            </m-stack>
          </template>
        </sidebar-cards>
      </template>
    </internal-page>
  </div>
</template>

<script>
import {
  InternalPage,
  PageHeader,
  VLoader,
  SecondaryButton,
  SidebarCards,
  PrimaryButton,
  HoursOfOperationList,
  NoContent
} from '@satellite/components/v2';
import { PPERequirementsEnum, DEFAULT_SCHEDULE } from '@satellite/../nova/core';
import WarehouseHeader from '@/components/elements/warehouse/WarehouseHeader.vue';
import WarehouseAddress from '@/components/elements/warehouse/WarehouseAddress.vue';
import WarehouseContact from '@/components/elements/warehouse/WarehouseContact.vue';

export default {
  components: {
    WarehouseAddress,
    InternalPage,
    PageHeader,
    VLoader,
    SecondaryButton,
    PrimaryButton,
    HoursOfOperationList,
    SidebarCards,
    NoContent,
    WarehouseHeader,
    WarehouseContact
  },
  props: {
    warehouseId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      warehouse: null,
      isLoading: true,
      favoriteWarehouseIds: [],
      DEFAULT_SCHEDULE
    };
  },
  computed: {
    isWarehouseFavorited() {
      return this.favoriteWarehouseIds.includes(this.warehouseId);
    }
  },
  methods: {
    async toggleFavorite() {
      if (this.isWarehouseFavorited) {
        await this.services.carrier.removeFavoriteWarehouse(this.warehouseId).then(response => {
          this.favoriteWarehouseIds = response;
          this.notify('Warehouse removed from favorites!');
        });
      } else {
        await this.services.carrier.addFavoriteWarehouse(this.warehouseId).then(response => {
          this.favoriteWarehouseIds = response;
          this.notify('Warehouse added to favorites!');
          this.mixpanel.track(this.mixpanel.events.ACTION.ADD_WAREHOUSE_TO_FAVORITES, {
            'Warehouse Name': this.warehouse.name,
            'Warehouse ID': this.warehouse.id
          });
        });
      }
    },
    bookWarehouse() {
      this.$store.commit('App/setWarehouseSearch', null);
      this.mixpanel.track(this.mixpanel.events.ACTION.BOOK_WAREHOUSE_ACTION, {
        'Warehouse Name': this.warehouse.name,
        'Warehouse ID': this.warehouse.id
      });
      this.services.carrier.setRecentWarehouse(this.warehouse);
      // Use warehouse customBookingUrl if available
      if (this.warehouse.customBookingUrl) {
        window.location = `${this.warehouse.customBookingUrl}?token=${this.$accessToken}&warehouseId=${this.warehouse.id}`;
      } else {
        this.$router.push({ name: 'appointment.book', params: { warehouseId: this.warehouse.id } });
      }
    },
    getPPERequirementIcon(ppeRequirement) {
      const index = Object.values(PPERequirementsEnum).indexOf(ppeRequirement);
      return Object.keys(PPERequirementsEnum)[index];
    },
    getYardMapPath(settings) {
      const map = settings.yardMap;
      return map
        ? this.novaCore.fileKeyToDownloadUrl(this.$store.getters['App/s3BaseUrl'], map)
        : null;
    }
  },
  beforeMount() {
    if (this.$isMrPreview && this.warehouseId) {
      sessionStorage.setItem('previewWarehouseId', this.warehouseId);
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.services.warehouse
      .getWarehouseById(
        this.warehouseId,
        { includeHierarchySettings: true },
        { joins: ['org||name,settings'] }
      )
      .then(response => {
        if (!this.$orgsToExcludeFromSchedulingPortal.includes(response.org.id)) {
          this.warehouse = response;
        } else {
          this.notify('Warehouse not found', 'error');
          this.$router.replace({ name: 'warehouse.select' });
        }
      })
      .finally(() => (this.isLoading = false));
    await this.services.carrier.getFavoriteWarehouses().then(response => {
      if (response?.length) {
        this.favoriteWarehouseIds = this.novaCore.pluck(response, 'id');
      }
    });
    this.mixpanel.track(this.mixpanel.events.VIEW.WAREHOUSE_DETAILS_PAGE, {
      'Warehouse Name': this.warehouse.name,
      'Warehouse ID': this.warehouse.id
    });
  }
};
</script>

<style lang="scss" scoped>
.amenities-grid {
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  @media (max-width: $smallDesktopBreakpoint) {
    display: flex;
    flex-wrap: wrap;
  }
}

.yard-map-img {
  max-width: 100%;
  width: 600px;
  max-height: 400px;
}
</style>
