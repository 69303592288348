<template>
  <div id="sidebar-menu">
    <m-group justify="space-between" align="center">
      <v-logo id="sidebar-logo"></v-logo>
      <icon-button
        class="display-tablet"
        test-id="close-sidebar-btn"
        no-bg
        icon-color="color-text-inverted"
        icon-size="25px"
        @click="$store.commit('App/setIsSidebarOpen', false)">
        close
      </icon-button>
    </m-group>
    <div class="divider"></div>
    <m-stack class="m-t-4" gap="spacing-2" align="center" justify="space-between">
      <sidebar-button
        v-for="menuItem in menuItems"
        :disabled="menuItem.isDisabled"
        :color="menuItem.color"
        :isActive="menuItem.isActive"
        icon-color="color-text-inverted"
        :leading-icon="menuItem.icon"
        :key="menuItem.label"
        :test-id="menuItem.testId"
        @click="
          menuItem.additionalAction?.();
          $router.push(menuItem.route);
          closeSidebar();
        ">
        {{ menuItem.label }}
      </sidebar-button>
    </m-stack>
  </div>
</template>

<script>
import { SidebarButton, IconButton, VLogo } from '@satellite/components/v2';

export default {
  name: 'SidebarMenu',
  components: { SidebarButton, IconButton, VLogo },
  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    // TODO make this real
    isUserFullyRegistered() {
      return true;
    },
    menuItems() {
      return [
        {
          label: 'Book appointment',
          icon: 'plus',
          route: this.$previewWarehouseId
            ? { name: 'appointment.book', params: { warehouseId: this.$previewWarehouseId } }
            : { name: 'warehouse.select' },
          color: 'color-success-60',
          isActive: false,
          isDisabled: this.isCompanyRegistrationPage,
          testId: 'book-appointment-btn',
          additionalAction: () => this.$store.commit('App/setWarehouseSearch', null)
        },
        {
          label: 'My appointments',
          route: { name: 'appointment.list' },
          icon: 'menu',
          isDisabled: this.$isMrPreview || this.isCompanyRegistrationPage,
          testId: 'my-appointments-btn',
          isActive: this.$route.name === 'appointment.list'
        }
      ];
    },
    isCompanyRegistrationPage() {
      return this.$route.name === 'register.company';
    }
  },
  methods: {
    closeSidebar() {
      this.$store.commit('App/setIsSidebarOpen', false);
    }
  }
};
</script>

<style scoped lang="scss">
.divider {
  border-bottom: 1px solid #005454;
  margin-left: 0;
  width: 100%;
}
::v-deep #sidebar-logo {
  padding: $m-spacing-2 $m-spacing-3 $m-spacing-1;
  img {
    height: 20px;
  }
}
</style>
